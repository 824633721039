@media (min-height: 68rem) {
	.content {
		display: flex;
		justify-content: center;
	}
}

.content {
	max-width: 45.25rem;
	height: fit-content;
	box-sizing: border-box;
	width: 100%;
	padding: 2.5rem;

	background: var(--neo-color-web-app-component-card-background-default);
	border-radius: 0.5rem;
	border: 0.0625rem solid var(--neo-color-global-border-neutral-soft-default);
	box-shadow: 0 0.25rem 1.125rem var(--neo-color-global-shadow-default);

	form {
		width: 100%;
	}

	.formContent {
		h1 {
			color: var(--neo-color-global-content-neutral-intense);
			font-family: 'PX-Grotesk', 'BlinkMacSystemFont', '-apple-system', 'Trebuchet MS', 'Helvetica',
				'HelveticaNeue', 'Helvetica Neue', 'Segoe UI', 'Roboto', 'Arial', 'sans-serif';
			font-weight: 700;
			font-size: 1.75rem;
			line-height: 2rem;
			margin: 0;
		}

		h2 {
			color: var(--neo-color-global-content-neutral-intense);
			font-family: 'PX-Grotesk', 'BlinkMacSystemFont', '-apple-system', 'Trebuchet MS', 'Helvetica',
				'HelveticaNeue', 'Helvetica Neue', 'Segoe UI', 'Roboto', 'Arial', 'sans-serif';
			font-weight: 700;
			font-size: 1.25rem;
			line-height: 1.5rem;
			margin: 0;
		}

		.tacs {
			color: var(--neo-color-global-content-neutral-moderate);
			font-weight: 400;
			font-size: 1rem;
			line-height: 1.5rem;

			& > * {
				margin: 0 0 2rem;
			}
		}
	}

	.hint {
		color: var(--neo-color-global-content-neutral-moderate);
		font-family: 'PX-Grotesk', 'BlinkMacSystemFont', '-apple-system', 'Trebuchet MS', 'Helvetica',
			'HelveticaNeue', 'Helvetica Neue', 'Segoe UI', 'Roboto', 'Arial', 'sans-serif';
		font-weight: 400;
		font-size: 0.75rem;
		line-height: 1rem;
		display: inline-block;
	}

	.hidden {
		display: none;
	}

	.submit {
		float: left;
	}
}

@media (max-width: 48rem) {
	.content {
		border: 0;
		max-width: inherit;
		padding: 5rem 2.5rem 2.5rem 2.5rem;

		.submit {
			float: none;
		}
	}
}

@media (max-width: 40rem) {
	.content {
		border: 0;
		max-width: inherit;
		padding: 5rem 1rem 1rem 1rem;
	}
}

@media (max-width: 20rem) {
	.submit {
		width: 100%;
	}
}

@media (max-width: 41.7rem) and (orientation: landscape) {
	.content {
		flex: 1;
	}
}

@media (max-width: 56rem) and (orientation: portrait) {
	.submit {
		display: flex;
		justify-content: center;
	}
}
