@import '../../theme/grid.scss';
@import '../../theme/font.scss';

.success {
	display: flex;
	flex-direction: column;
	flex: 1;
	max-width: 880px;
	margin: 0 auto;

	.content {
		display: flex;
		flex-direction: column;
		font-size: 1rem;
		line-height: 1.625rem;
		padding: 5rem 1rem 0;
		.message {
			width: 100%;
			word-break: break-word;
			h1 {
				font-size: 1.6125rem;
				font-weight: bold;
				line-height: 2.25rem;
				font-family: $font-px-grotesk;
				margin-bottom: 1.5rem;
				margin-top: 0;
			}

			h2 {
				font-size: 1.5rem;
				font-weight: bold;
				line-height: 2rem;
				font-family: $font-px-grotesk;
				margin-bottom: 1rem;
				margin-top: 0;
			}

			h3 {
				font-size: 1.125rem;
				font-weight: bold;
				line-height: 1.625rem;
				font-family: $font-px-grotesk;
				margin-bottom: 0.5rem;
				margin-top: 0;
			}

			h4 {
				font-size: 1rem;
				font-weight: bold;
				line-height: 1.5rem;
				font-family: $font-px-grotesk;
				margin-bottom: 0.5rem;
				margin-top: 0;
			}

			h5 {
				font-size: 0.875rem;
				font-weight: bold;
				line-height: 1.25rem;
				font-family: $font-px-grotesk;
				margin-bottom: 0.5rem;
				margin-top: 0;
			}

			h6 {
				font-size: 0.75rem;
				font-weight: bold;
				line-height: 1.125rem;
				font-family: $font-px-grotesk;
				margin-bottom: 0.5rem;
				margin-top: 0;
			}

			p {
				font-size: 1rem;
				line-height: 1.625rem;
				font-family: $font-px-grotesk;
			}

			> span {
				color: var(--neo-color-global-content-neutral-intense) !important;
				font-size: 1rem;
			}

			dt {
				font-weight: bold;
				line-height: 1.5rem;
				font-size: 1rem;
				&:not(:first-child) {
					margin-top: 0.5rem;
				}
			}

			dd {
				font-size: 1rem;
				line-height: 1.5rem;
				font-weight: normal;
			}
		}
	}
}
