.wrapper {
	display: flex;
	gap: 1rem;
	padding: 0.5rem 1rem;

	border: 1px solid var(--neo-color-global-border-neutral-soft-default);
	border-radius: 0.5rem;

	@media screen and (max-width: 64rem) {
		width: 100%;
	}

	width: fit-content;
	min-width: calc(50% - var(--grid-gap));

	.name {
		color: var(--neo-color-global-content-neutral-intense);
		font-weight: 400;
	}

	.email {
		align-items: center;
		gap: 0.5rem;
		color: var(--neo-color-global-content-neutral-moderate);
		font-size: 0.75rem;
		line-height: 1rem;
		display: block;
		float: left;
		word-break: break-all;
		word-wrap: break-word;
		position: relative;
		padding-left: 1rem;

		&:before {
			content: '';
			height: 0.625rem;
			width: 0.625rem;
			margin: 0.1875rem 0.3125rem 0.125rem 0;
			position: absolute;
			left: 0;
		}
	}

	> div {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&.GOOGLE div.email:before {
		background: url('./img/logo_google.svg');
	}

	&.MICROSOFT div.email:before {
		background: url('./img/logo_microsoft.svg');
	}

	&.APPLE div.email:before {
		background: url('./img/logo_apple.svg') no-repeat center;
	}
}
