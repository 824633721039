:root {
	--grid-gap: 1rem;
	--grid-column-width: 8.25rem;
	--column-width: 8.25rem;
	--widht-2: calc((var(--grid-column-width) * 2) + (var(--grid-gap) * 1));
	--width-4: calc((var(--grid-column-width) * 4) + (var(--grid-gap) * 3));
}

.row {
	display: flex;
	width: 100%;
	float: left;

	> div {
		padding-right: var(--grid-gap);
		flex-grow: 1;
		align-content: flex-start;
	}
}

@media (max-width: 64rem) {
	.row {
		> div:only-child {
			padding-right: 0;
		}

		> div {
			padding: 0 0 1rem 0;
			flex: 1 1 auto;
			max-width: 100%;
		}

		> div:last-child {
			padding: 0;
		}

		flex-direction: column;
	}
}

.center {
	justify-content: center;
}

@for $i from 1 through 12 {
	.width#{$i} {
		flex: $i 1 auto;
		max-width: calc(100% / 12 * $i);
		@media (max-width: 64rem) {
			&.mobilefw {
				flex: 1 1 auto;
				max-width: 100%;
			}
		}
	}
}
