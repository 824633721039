@import '../../theme/grid.scss';

.mwlogo {
	max-width: 6.5rem;
}

.success {
	display: flex;
	justify-content: center;
	flex-direction: column;
	flex: 1;

	max-width: 45.25rem;
	height: fit-content;
	box-sizing: border-box;
	padding: 2.5rem;

	background: var(--neo-color-web-app-component-card-background-default);
	border-radius: 0.5rem;
	border: 0.125rem solid var(--neo-color-global-border-neutral-soft-default);

	.content {
		display: flex;
		flex-direction: column;

		font-size: 1.125rem;
		line-height: 1.625rem;

		h1 {
			color: var(--neo-color-global-content-neutral-intense);
			font-family: 'PX-Grotesk', 'BlinkMacSystemFont', '-apple-system', 'Trebuchet MS', 'Helvetica',
				'HelveticaNeue', 'Helvetica Neue', 'Segoe UI', 'Roboto', 'Arial', 'sans-serif';
			font-weight: 700;
			font-size: 1.75rem;
			line-height: 2rem;
			margin: 0;
		}

		h2 {
			color: var(--neo-color-global-content-neutral-intense);
			em {
				font-family: 'PX-Grotesk', 'BlinkMacSystemFont', '-apple-system', 'Trebuchet MS',
					'Helvetica', 'HelveticaNeue', 'Helvetica Neue', 'Segoe UI', 'Roboto', 'Arial',
					'sans-serif';
				font-weight: 700;
				font-size: 2rem;
				line-height: 2.25rem;
				font-style: normal;
			}

			font-family: DIN-Next, sans-serif;
			font-size: 2rem;
			line-height: 2.25rem;
			margin: 0 0 2.5rem;
			text-align: left;
		}

		.message,
		.resend {
			font-family: 'PX-Grotesk', 'BlinkMacSystemFont', '-apple-system', 'Trebuchet MS', 'Helvetica',
				'HelveticaNeue', 'Helvetica Neue', 'Segoe UI', 'Roboto', 'Arial', 'sans-serif';
			font-weight: 400;
			font-size: 1rem;
			line-height: 1.5rem;
			font-style: normal;
			margin-top: 1rem;

			text-align: left;

			strong {
				line-break: auto;
				word-break: break-all;
			}

			div.wrapper {
				margin: 0.5rem 0;
			}

			.success {
				padding: 0;
				border: 0;
				margin-top: 1.5rem;
			}
		}

		.checkYourMail {
			max-width: 20rem;
		}
	}
}

@media (max-width: 48rem) {
	.success {
		border: 0;
		max-width: inherit;
		padding: 5rem 2.5rem 2.5rem 2.5rem;

		justify-content: flex-start;

		.content {
			.resend {
				.wrapper {
					float: none;
				}
			}
		}
		.resend div.wrapper {
			float: none;
		}
	}
}

@media (max-width: 40rem) {
	.success {
		padding: 5.5rem 1rem 1rem 1rem;
	}
}

@media (max-width: 20rem) {
	.resend div.wrapper {
		width: 100%;
	}
}
