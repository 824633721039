@import '../../theme/font.scss';

.activate {
	margin-top: 20rem;

	.content {
		display: flex;
		flex-direction: column;

		h2 {
			font-family: $font-px-grotesk;
			font-size: 36px;
			font-weight: 700;
			line-height: 36px;
			letter-spacing: 0em;
			text-align: center;
		}

		.message {
			//styleName: text-lg/leading-[24]/normal;
			font-family: $font-px-grotesk;
			font-size: 18px;
			font-weight: 400;
			line-height: 24px;
			letter-spacing: 0em;
			text-align: center;
		}

		.spinner {
			margin-top: 2rem;
			height: 4rem;
		}
	}
}
