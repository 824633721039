@import '../../theme/form.scss';
@import '../../theme/grid.scss';
@import '../../theme/group.scss';

@media (max-height: 68rem) {
	.content {
		form {
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 100%;
		}
	}
}
