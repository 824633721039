@import '../../../theme/grid';
@import '../../../theme/group';

.headline {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	word-break: break-word;
}

.subline {
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
	margin: 0 0 1rem 0;
}

.headline {
	margin-bottom: 1rem;
}

.GOOGLE {
	gap: 1rem;
}

@media (max-width: 64rem) and (orientation: portrait) {
	.headline {
		justify-content: start;
		gap: var(--grid-gap);
	}
}

@media (max-width: 56rem) and (orientation: landscape) {
	.headline {
		justify-content: start;
		gap: var(--grid-gap);
	}
}

@media (max-width: 48rem) {
	.group {
		gap: 1rem;
	}
}

@media (max-width: 41.7rem) and (orientation: portrait) {
	.headline {
		flex-direction: column;
	}
}

@media (max-width: 41.7rem) and (orientation: landscape) {
	.row {
		flex-direction: column;
		width: 100%;
	}
}
