@import '../../../theme/grid';
@import '../../../theme/group';

/** iPhone SE portrait **/
@media (max-width: 41.7rem) and (orientation: portrait) {
	.headline {
		button {
			display: none;
		}
	}
}

/** iPhone SE landscape **/
@media (max-width: 41.7rem) and (orientation: landscape) {
	.row {
		width: 100%;
	}

	.width2 {
		width: calc(50% - (var(--grid-gap) / 2));
	}
}
