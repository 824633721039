@import 'theme';

.idp {
	display: flex;
	align-items: center;

	text-decoration: none;
	width: 100%;
	height: 3rem;
	padding: 0;

	margin-bottom: 0.5rem;

	background: var(--white);

	border: 0.0625rem solid var(--gray-100);
	border-radius: 0.125rem;

	color: var(--gray-600);

	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 600;
	font-family:
		PX-Grotesk,
		BlinkMacSystemFont,
		-apple-system,
		Trebuchet MS,
		Helvetica,
		'HelveticaNeue',
		'Helvetica Neue',
		'Segoe UI',
		Roboto,
		Arial,
		sans-serif;

	&:before {
		content: '';
		width: 4rem;
	}

	&:focus,
	&:focus-visible,
	&:focus-within {
		@include focussable();
	}

	&:hover {
		background: var(--gray-25);
	}

	&.google > span:before {
		background-image: url('./img/logo_google.svg');
	}

	&.microsoft > span:before {
		background-image: url('./img/logo_microsoft.svg');
	}

	&.apple > span:before {
		background-position-y: 0;
		background-image: url('./img/logo_apple.svg');
	}

	span {
		box-sizing: border-box;

		display: flex;
		justify-content: center;
		align-items: center;
		gap: 1rem;
		height: 100%;

		&:before {
			display: block;
			content: '';
			background-position: center;
			background-repeat: no-repeat;
			width: 1.5rem;
			height: 1.5rem;
		}
	}

	&.clicked {
		span {
			background: #eeeeee;
			border-color: var(--gray-100);
		}
	}
}

.last {
	margin-bottom: 1.5rem;
}
