.sipgateLogo {
	display: block;
	position: fixed;
	height: auto;
	width: 8.125rem;
	z-index: 1001;
	top: 0;
	left: 0;
	margin: 1rem;

	&.isDark {
		img {
			filter: invert(1);
		}
	}
}

.hide {
	display: none;
}

.wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	width: 100%;
	margin-top: auto;
	margin-bottom: auto;
	.container {
		display: flex;
		flex-direction: row;
		position: relative;
		background-color: var(--neo-color-web-app-component-card-background-default);
		border-radius: 0.5rem;
		box-shadow: 0 0.25rem 1.125rem var(--neo-color-global-shadow-default);
		border: 1px solid var(--neo-color-global-border-neutral-soft-default);
		width: 47rem;
		overflow: hidden;
		.close {
			position: absolute;
			top: 0.5rem;
			right: 0.5rem;
		}
	}

	.content {
		display: flex;
		justify-content: center;

		padding: 1.5rem 1.5rem 3rem 1.5rem;

		border-radius: 0.5rem 0 0 0.5rem;
		background-color: var(--neo-color-web-app-component-card-background-default);
		border-right: 0;

		h2 {
			font-weight: 700;
			font-size: 1.75rem;
			line-height: 2rem;

			margin-top: 1.5rem;
			margin-bottom: 1rem;
		}

		.form {
			width: 20rem;

			& > * {
				margin-bottom: 1rem;
			}
		}

		.tacs {
			color: var(--neo-color-global-content-neutral-moderate);
			font-weight: 400;
			font-size: 1rem;
			line-height: 1.5rem;

			& > * {
				margin: 1.5rem 0 0;
			}
		}

		.hr {
			border-top: 1px solid var(--neo-color-global-border-neutral-soft-default);
			text-align: center;
			margin-bottom: 1.5rem;
			margin-top: 1.5rem;

			&:after {
				display: inline-block;
				position: relative;
				top: -0.69rem;
				padding: 0 0.5rem;
				background-color: var(--neo-color-web-app-component-card-background-default);
				font-size: 1rem;
				color: var(--neo-color-global-content-neutral-intense);
				line-height: 0.75rem;
				content: attr(data-content);
			}
		}

		&:last-child {
			margin-bottom: 1.5rem;
		}
		flex-basis: 55%;
	}

	.info {
		display: flex;
		flex-direction: column;
		padding: 1.5rem 1.5rem 3rem 1.5rem;
		flex: 1;
		flex-basis: 45%;
		border-radius: 0 0.5rem 0.5rem 0;
		border-left: 0;

		background-color: var(--neo-color-web-app-surface-signup-aside);

		h2 {
			color: var(--neo-color-global-content-neutral-intense);
			font-family: 'PX-Grotesk', 'BlinkMacSystemFont', '-apple-system', 'Trebuchet MS', 'Helvetica',
				'HelveticaNeue', 'Helvetica Neue', 'Segoe UI', 'Roboto', 'Arial', 'sans-serif';
			font-weight: 700;
			font-size: 1.25rem;
			line-height: 2rem;

			margin-top: 1.5rem;
			margin-bottom: 1rem;
		}

		ul {
			color: var(--neo-color-global-content-neutral-intense);
			padding: 0;
			font-family: 'PX-Grotesk', 'BlinkMacSystemFont', '-apple-system', 'Trebuchet MS', 'Helvetica',
				'HelveticaNeue', 'Helvetica Neue', 'Segoe UI', 'Roboto', 'Arial', 'sans-serif';
			font-weight: 400;
			font-size: 1rem;
			line-height: 1.5rem;
			list-style: none;
			margin: 0;

			li {
				&:before {
					content: url('../../../media/images/marker.svg');
				}

				display: flex;
				gap: 0.5rem;
				align-content: baseline;
				margin-bottom: 1rem;
			}

			:last-child {
				margin-bottom: 0;
			}
		}

		.hint {
			margin-top: 1.5rem;

			color: var(--neo-color-global-content-neutral-moderate);
			font-weight: 400;
			font-size: 1rem;
			line-height: 1.5rem;
		}
	}
}

@media (max-width: 48rem) {
	.wrapper {
		align-items: start;

		.container {
			flex-direction: column;
			align-self: center;
			margin: 5rem 0 auto;
			max-width: 27rem;
			.info {
				border-radius: 0 0 0.5rem 0.5rem;
				border-top: 0;

				align-items: center;

				& > * {
					width: 20rem;
				}

				ul > li:last-child {
					margin-bottom: 1.5rem;
				}

				.hint {
					margin-top: 0;
				}
			}

			.content {
				border-radius: 0.5rem 0.5rem 0 0;
				border: 0;
				padding-bottom: 3rem;
			}
		}
	}
}

@media (max-width: 40rem) {
	.sipgateLogo {
		display: block;
		position: relative;
		height: auto;
		width: 8.125rem;
		z-index: 1001;
		top: 0;
		left: 0;
		margin: 1rem;
		align-self: start;
	}

	.wrapper {
		.container {
			flex-direction: column;
			align-items: center;
			width: 100%;
			height: 100%;
			margin-top: 0;
			max-width: none;
			border-radius: 0;
			border: 0;
			box-shadow: none;

			.content {
				border: 0;
				flex-basis: auto;
			}

			.info {
				align-items: center;
				width: 100%;
				border: 0;
				border-radius: 0;

				& > * {
					width: 20rem;
				}
			}
		}
	}
}

@media (max-width: 20rem) {
	.wrapper {
		.container {
			.content {
				align-items: center;
				padding-left: 0;
				padding-right: 0;

				.form {
					display: flex;
					flex-direction: column;
					align-items: center;
					margin-bottom: 0;

					& > * {
						width: 18rem;
					}

					form {
						button {
							margin-bottom: 0;
						}
					}
				}
			}

			.info {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding-left: 0;
				padding-right: 0;

				& > * {
					width: 18rem;
				}

				.hint {
					margin-top: 0;
				}
			}
		}
	}
}
