@import '../../theme/font.scss';

.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;

	margin-top: 15vh;
	gap: 1rem;

	.content {
		display: flex;
		flex-direction: column;
		margin: 0 1rem;

		h2,
		.message {
			font-family: $font-px-grotesk;
			font-size: 1.25rem;
			font-weight: 700;
			line-height: 1.5rem;
			text-align: center;
		}

		.message {
			font-family: $font-px-grotesk;
			font-size: 1rem;
			font-weight: 400;
			line-height: 2rem;
			text-align: center;
			padding-bottom: 1rem;
		}

		.spinner {
			margin-top: 2rem;
			height: 4rem;
		}

		.button {
			display: flex;
			justify-content: center;
		}
	}
}
