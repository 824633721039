.visually-hidden {
	position: absolute;
	overflow: hidden;
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	width: 0.0625rem;
	height: 0.0625rem;
	white-space: nowrap;
	user-select: none;
}

@mixin focussable() {
	&:focus-visible {
		box-shadow: var(--neo-color-global-border-static-focus) 0 0 0 0.1875rem inset;
	}
}
